import React, {useEffect, useState} from 'react';

import { Icon } from '@iconify-icon/react';
// import { Link } from "react-router-dom";
// import { StyledButton_v2 } from '../components/Button_v2';
import { useAppDispatch } from '../stateStore';
import { TrueFalseServices } from '../reducers/TrueFalseSlice';
import axios  from 'axios';
import { urls } from '../utils/constants';
import { FormattedMessage, useIntl } from 'react-intl';
import Input from '../components/Forms/Input';
import Label from '../components/Forms/Label';
import Textarea from '../components/Forms/Textarea';
import { Helmet } from 'react-helmet-async';

import * as z from 'zod';

// validation schema
const demoSchema = z.object({
    name: z.string(),
    to: z.string().email("Alamat email tidak valid."),
    text: z.string()
        .nonempty({ message: "Masukkan pesan." })
        .max(500, "Tidak boleh lebih dari 500 karatker."),
    company: z.string().max(100, "Tidak boleh lebih dari 100 karakter."),
    telp: z.string()
        .nonempty({ message: "Masukkan No Telp kamu." })
        .regex(/^\d+$/, { message: "No Telp harus berupa angka." }),
});

type DemoFormData = z.infer<typeof demoSchema>;

type Errors = Partial<Record<keyof DemoFormData, { _errors: string[] }>>;

interface EmailData {
    name: string;
    to: string;
    subject: string;
    text: string;
    company: string;
    telp: string;
}

interface ResponseMessage {
    message: string;
}

function Contact() {
    const [errors, setErrors] = useState<Partial<Record<keyof DemoFormData, string[]>>>({});
    const [responseMessage, setResponseMessage] = useState<string>('');
    const [emailData, setEmailData] = useState<EmailData>({
        name: '',
        to: '',
        subject: 'Contact',
        text: '',
        company: '',
        telp: ''
    });

    
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        
        const { name, value } = e.target;
        setEmailData({
            ...emailData,
            [name]: value
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            // ZOD form validation
            const result = demoSchema.safeParse(emailData);
            
            if (!result.success) {
                const fieldErrors = result.error.format() as Partial<Errors>;
                const allErrors: Partial<Record<keyof DemoFormData, string[]>> = {};

                Object.entries(fieldErrors).forEach(([key, value]) => {
                    const fieldError = value?._errors;
                    if (fieldError) {
                        allErrors[key as keyof DemoFormData] = fieldError;
                    }
                });
                
                setErrors(allErrors);

            } else {
                console.log("Form data is valid! Saatnya kirim email", emailData);
                const response = await axios.post<ResponseMessage>(`${urls.API_FILAMENT}/contact-messages`, emailData);
                setResponseMessage(response.data.message);
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                setResponseMessage(`Error: ${error.response.data.message}`);
            } else {
                setResponseMessage(`Unexpected Error: ${error instanceof Error ? error.message : 'An unknown error occurred'}`);
            }
        }
    };

    const dispatch = useAppDispatch()
    const intl = useIntl();
    useEffect(() => {
        dispatch(TrueFalseServices.actions.setToTrue())
    }, []);
    return (
        <>
            <Helmet>
                <title>Contact - Delta Solusi Nusantara</title>
                <meta name="description" content="Welcome to the Landing Page of MyApp." />
                <meta name="keywords" content="landing, myapp, react" />
                <meta property="og:description" content="PT. Delta Solusi Nusantara is in the business of providing innovative solutions to enable digital transformation of enterprises around the world. We specialize in providing enterprise resourcing planning software, & professional services." />
                <meta property="og:title" content="PT.Delta Solusi Nusantara" />
                <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
                <meta property="og:url" content="https://dsnusantara.com" />
            </Helmet>
            
            <main className='w-full' >
                <section className='flex flex-col lg:flex-row '>
                    <div className='basis-1/2 flex flex-col gap-y-6 my-16 px-5 lg:px-20'>
                        <div className='py-1.5 px-2 bg-red-50 flex flex-row rounded-lg justify-center gap-x-2 items-center max-w-fit'>
                            <div className='p-1 bg-red-500 flex justify-center items-center rounded-lg'>
                                <Icon icon="fluent:apps-16-regular" className='text-white text-lg'/>
                            </div>
                            <div className='text-red-500'><FormattedMessage id="contact_tag_1" /></div>
                        </div>
                        <h2 className='text-4xl font-[550]'><FormattedMessage id="contact_tag_2" /></h2>
                        <p className='text-base'><FormattedMessage id="contact_tag_3" /></p>
                        <div>
                            <div className='border rounded-xl p-3 w-full md:w-4/6 lg:w-4/6 mb-8 relative overflow-hidden border-outline cursor-pointer'>
                                <a href="https://wa.me/6282170074012 " rel="noopener noreferrer" target="_blank">
                                    <Icon icon="ic:baseline-whatsapp" className='text-red-500 text-[112px] opacity-20 absolute top-0 -right-12'/>
                                    <div className='py-1.5 px-2 bg-red-50 flex flex-row rounded-lg justify-center gap-x-2 items-center max-w-fit mb-8'>
                                        <div className='bg-red-500 p-1 flex justify-center items-center rounded-lg'>
                                            <Icon icon="ic:baseline-whatsapp" className='text-white text-lg'/>
                                        </div>
                                    </div>
                                    <h4 className='text-lg font-[550] mb-2'>Whatsapp</h4>
                                    <p className='text-base'><FormattedMessage id="contact_whatsapp_typo"/></p>
                                </a>    
                            </div>

                            <div className='border rounded-xl p-3 w-full md:w-4/6 lg:w-4/6 relative overflow-hidden border-outline cursor-pointer'>
                                <a href="tel:+622154353008" rel="noopener noreferrer">
                                    <Icon icon="ri:customer-service-2-line" className='text-red-500 text-[112px] opacity-20 absolute top-0 -right-12'/>
                                    <div className='py-1.5 px-2 bg-red-50 flex flex-row rounded-lg justify-center gap-x-2 items-center max-w-fit mb-8'>
                                        <div className='bg-red-500 p-1 flex justify-center items-center rounded-lg'>
                                            <Icon icon="ri:customer-service-2-line"className='text-white text-lg' />
                                        </div>
                                    </div>
                                    <h4 className='text-lg font-[550] mb-2'>Call Center</h4>
                                    <p className='text-base'><FormattedMessage id="contact_call_center_typo"/></p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='basis-1/2 bg-gradient-second-pattern bg-no-repeat bg-cover m-4 rounded-3xl'>
                        <div className='mx-5 md:mx-16 lg:mx-16 my-20 bg-white rounded-3xl '>
                            <form className='border rounded-3xl py-8 px-6 flex flex-col gap-y-8' onSubmit={handleSubmit}>
                                <div className='flex flex-col gap-y-3'>
                                    <Label for="name" title={intl.formatMessage({ id: 'input_nama' })} />
                                    <Input 
                                        type='text' 
                                        placeholder={intl.formatMessage({ id: 'placeholder_input_nama' })} 
                                        value={emailData.name}
                                        name="name"
                                        id="name"
                                        onChange={handleChange} />
                                        {errors.name && errors.name.map((error, index) => (
                                            <div key={index} className="text-sm text-red-500 font-medium">
                                                {error}
                                            </div>
                                        ))}
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <Label for="company" title={intl.formatMessage({ id: 'input_nama_perusahaan' })} />
                                    <Input 
                                        type='text' 
                                        placeholder={intl.formatMessage({ id: 'placeholder_input_nama_perusahaan' })} 
                                        value={emailData.company}
                                        name="company"
                                        id="company"
                                        onChange={handleChange} />
                                    {errors.company && errors.company.map((error, index) => (
                                    <div key={index} className="text-sm text-red-500 font-medium">
                                        {error}
                                    </div>
                                ))}
                                </div>
                                <div className='flex flex-col lg:flex-row gap-x-4 gap-y-8'>
                                    <div className='basis-1/2 flex flex-col gap-y-3'>
                                        <Label for="email" title={intl.formatMessage({ id: 'input_email' })}/>
                                        <Input 
                                            type='email' 
                                            placeholder={intl.formatMessage({ id: 'placeholder_input_email' })}
                                            value={emailData.to}
                                            name="to"
                                            id="email"
                                            onChange={handleChange} />
                                        {errors.to && errors.to.map((error, index) => (
                                            <div key={index} className="text-sm text-red-500 font-medium">
                                                {error}
                                            </div>
                                        ))}
                                    </div>
                                    <div className='basis-1/2 flex flex-col gap-y-3'>
                                        <Label for="telp" title={intl.formatMessage({ id: 'input_wa' })}/>
                                        <Input 
                                            type='text' 
                                            placeholder={intl.formatMessage({ id: 'placeholder_input_wa' })}
                                            value={emailData.telp}
                                            name="telp"
                                            id="telp"
                                            onChange={handleChange} />
                                        {errors.telp && errors.telp.map((error, index) => (
                                            <div key={index} className="text-sm text-red-500 font-medium">
                                                {error}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='flex flex-col gap-y-3'>
                                    <Label for="text" title={intl.formatMessage({ id: 'placeholder_input_nama_perusahaan' })}/>
                                    <Textarea name="text" id="text" placeholder={intl.formatMessage({ id: 'placeholder_input_pesan' })} rows={4} value={emailData.text} onChange={handleChange} />
                                    {errors.text && errors.text.map((error, index) => (
                                        <div key={index} className="text-sm text-red-500 font-medium">
                                            {error}
                                        </div>
                                    ))}
                                </div>
                                {/* <Link to="/contact">
                                    <StyledButton_v2 type='primary' className='w-full'>Kirim</StyledButton_v2>
                                </Link> */}
                                <button type="submit" className="py-3 px-3 rounded-lg bg-red-500 hover:bg-red-600 text-gray-100 text-sm font-medium">
                                    <FormattedMessage id="input_submit"/>
                                </button>
                                
                            </form>
                        </div>
                    </div>
                </section>
                {responseMessage && <p className='hidden'>{responseMessage}</p>}
            </main>
            
        </>

    );
}
export default Contact;
    
