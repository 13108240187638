import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios"
import { urls } from '../../utils/constants';
import { Helmet } from 'react-helmet-async';
import styles from './detailblog.module.css';

interface BlogData {
    data: {
        id: number;
        title: string;
        slug: string;
        user: {
            name: string;
            photo: string;
        };
        content: string;
        state: string;
        banner: string;
        summary: string;
        category: {
            name: string;
        },
        updated_at: string
    }
}

function DetailBlog() {
    const decodeHTML = (html: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        return doc.body.innerHTML;
    };
    const formatDate = (isoString: string) => {
        const date = new Date(isoString);
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    }
    const { documentId } = useParams();
    const [blog, setBlog] = useState <BlogData>();
    const fetchBlog  = async () => {
        
        await axios.get<BlogData>(urls.API_FILAMENT + '/articles/' + documentId)
        // await axios.get<BlogData>('https://dash.dsnusantara.com/api/administrator/articles' + documentId)
        .then(response => {
            setBlog(response.data);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
    
    useEffect(() => {
        fetchBlog();
    }, [])

    return (
        <>
            <Helmet>
                <title>Detail Blogs - Delta Solusi Nusantara</title>
                <meta name="description" content="Explore insightful articles on Odoo ERP solutions, tips for optimizing your business processes, and the latest updates on accounting, inventory, CRM, and more." />
                <meta name="keywords" content="Odoo ERP, business management, ERP software, accounting, inventory management, CRM, Odoo blog, business optimization, Odoo updates, Odoo tutorials" />
            </Helmet>  
            { blog ? 
                <section>
                    <div className='w-auto lg:w-[800px] mx-5 lg:mx-auto text-center'>
                        {/* <p className='text-medium font-semibold text-[#3B82F6] mb-[16px]'>{blog?.data} - {blog.reading_time} min read</p> */}
                        <h1 className='display-sm lg:display-lg font-semibold mb-[24px]'>{blog.data.title}</h1>
                        <p className='text-medium lg:text-extralarge font-medium'>{blog.data.summary}</p>
                        {/* AUTHOR */}
                        <p className='text-center text-medium text-primary font-semibold text-black mt-10'>{blog.data.category.name}</p>
                        <div className='flex flex-row mt-5  justify-center items-center'>
                            <div className='w-12 h-12 rounded-full bg-cover border' style={{backgroundImage: `url(${urls.API_FILAMENT_STORAGE + blog.data.user.photo})`}}></div>
                            <div className='flex flex-col ml-3'>
                                <p className='text-left text-large font-semibold text-black'>{blog.data.user.name}</p>
                            </div>
                        </div>
                        
                    </div>
                    {/* BANNER */}
                    
                    <div className="w-full bg-cover lg:h-[516px] h-[250px] border mt-24 mb-16" style={{backgroundImage: `url(${urls.API_FILAMENT_STORAGE + blog.data.banner})`}}></div>
                    {/* CONTENT */}
                    <div className="w-auto lg:w-[800px] mx-5 lg:mx-auto">
                        <p className='text-medium lg:text-extralarge font-medium'>Terakhir di update: {formatDate(blog.data.updated_at)}</p>
                        
                        {blog.data.content && 
                            <div className={styles.content} dangerouslySetInnerHTML={{ __html: decodeHTML(blog.data.content) }} />
                        }
                    </div>
                </section>
            : 'Loading ... '}
        
        </>
    )
}
export default DetailBlog;