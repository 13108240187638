import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import axios from "axios"
import { urls } from '../../utils/constants';
import { Link } from "react-router-dom";

interface BlogData {
    id: number;
    title: string;
    slug: string;
    user: {
        name: string;
    };
    content: string;
    state: string;
    banner: string;
    summary: string;
    category: {
        name: string;
    }
}

interface ApiResponse {
    data: BlogData[];
}

function Blog() {
    const [blog, setBlog] = useState <ApiResponse>();
    const fetchBlog  = async () => {
        
        // await axios.get<ApiResponse>('https://dash.dsnusantara.com/api/administrator/articles', { withCredentials: true })
        await axios.get<ApiResponse>(urls.API_FILAMENT + '/articles')
        .then(response => {
            setBlog(response.data);
        })
        .catch(error => {
            if (axios.isAxiosError(error)) {
            console.error('API Request Failed:', error.response?.data || error.message);
            } else {
            console.error('Unknown error:', error);
            }
        });
    }
    
    useEffect(() => {
        fetchBlog();
    }, [])

    return (
        <>
            <Helmet>
                <title>Blogs - Delta Solusi Nusantara</title>
                <meta name="description" content="Explore insightful articles on Odoo ERP solutions, tips for optimizing your business processes, and the latest updates on accounting, inventory, CRM, and more." />
                <meta name="keywords" content="Odoo ERP, business management, ERP software, accounting, inventory management, CRM, Odoo blog, business optimization, Odoo updates, Odoo tutorials" />
            </Helmet>  
            <main>

                <section className="px-5 lg:px-20 mb-[125px]">
                    <h1 className="font-bold font-medium lg:font-semibold text-5xl lg:text-7xl leading-[61px] lg:leading-[90px] tracking[-2%]">
                    Discover Insights and Innovations in ERP Solutions
                    </h1>
                </section>
                <section className="mx-5 lg:mx-28 py-0 mb-10 lg:mb-24">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        {blog && blog.data.map((item) => {
                            return (
                                <Link to={`/blog/${item.slug}/${item.id}`} key={item.id}>
                                        <div className="w-full h-[240px] rounded-lg bg-cover mb-4" style={{backgroundImage: `url(${urls.API_FILAMENT_STORAGE + item.banner})`}}>
                                        </div>
                                        <div className="w-full h-44 flex flex-col gap-y-2">
                                            <p className='text-small'>Ditulis oleh {item.user.name} : <span className='font-semibold text-[#3B82F6]'>{item.category.name}</span></p>
                                            <h3 className='display-xs font-semibold text-wrap'>{item.title?.substring(0, 40)} {item.title?.length > 39 ? '...' : ''}</h3>
                                            <p className='text-medium font-medium'>{item.summary?.substring(0, 100)} {item.summary?.length > 99 ? '...' : ''} </p>
                                        </div>
                                </Link>
                            )
                        })}
                    </div>
                </section>
            </main>
        </>
    )
}
export default Blog;