import React, { useState } from 'react';
import { Icon } from '@iconify-icon/react';
import { Link } from "react-router-dom";
import { StyledButton_v2 } from '../UI/Button_v2';
import { useAppDispatch } from '../../stateStore'
import { TrueFalseServices } from '../../reducers/TrueFalseSlice'
import { LocaleServices } from '../../reducers/LocaleSlice'
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { StyledIcon } from '../../components/UI/Icons';
// import { Dropdown, Option } from '../UI/Dropdown';
import flagID from '../../assets/flags/ID.png';
import flagEN from '../../assets/flags/EN.png';

function Navbar_v2() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const [openMenu, setOpenMenu] = useState<boolean>()
    const [openProductMenu, setOpenProductMenu] = useState<boolean>()

    const clickOpenMenu = () => {
        setOpenMenu(!openMenu)
        // store to redux
        if (!openMenu) {
            dispatch(TrueFalseServices.actions.setToFalse())
        } else {
            dispatch(TrueFalseServices.actions.setToTrue())
        }
    }
    const clickNav = (url: string) =>  (event: React.MouseEvent<HTMLLIElement>) => {
        event.preventDefault();
        dispatch(TrueFalseServices.actions.setToTrue())
        setOpenProductMenu(false)
        setOpenMenu(false)
        setOpenProducts(false)
        setOpenLanguage(false)
        navigate(url);
    }
    const openProductNav = () => {
        setOpenProductMenu(!openProductMenu)
    }
    // const [selectedLocale, setSelectedLocale] = useState('en');
    // const changeLocaleHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
        //     setSelectedLocale(event.target.value);
        //     dispatch(LocaleServices.actions.changeLocale(event.target.value))
        // }

    const [selectedLocale, setSelectedLocale] = useState('id');
    const [openLanguage, setOpenLanguage] = useState(false);
    const [openProducts, setOpenProducts] = useState(false);

    const changelang = (lang: string) => {
        setSelectedLocale(lang.toLowerCase());
        dispatch(LocaleServices.actions.changeLocale(lang.toLowerCase()))
        setOpenLanguage(false);
    }
    return (
        <>
        <header className='fixed w-full bg-white z-40'>
            <div className="w-full h-16 border-b border-gray-200 flex justify-between px-4 md:px-20 lg:px-20 items-center" >
                <div className='flex flex-row items-center'>
                    <ul>
                        <li className='w-auto list-none mr-4' onClick={clickNav('/')}>
                            <img alt="Logo" className='cursor-pointer' src={process.env.PUBLIC_URL + '/img/logo_dsn.svg'} style={{backgroundSize: 'cover', width: '140px'}}/>
                        </li>
                    </ul>
                
                    <nav className="hidden lg:block"> 
                        <ul className="flex">
                            <li>
                                <div className='flex flex-col'>
                                    <StyledButton_v2 type='tertiary' className='hidden lg:block' onClick={() => setOpenProducts(!openProducts)}>
                                        <div className='flex flex-row items-center gap-x-2 justify-between text-sm font-semibold'>
                                            <p><FormattedMessage id="nav_product" /></p>
                                            { openProducts ? <Icon className='self-end' icon="ic:round-keyboard-arrow-up" /> : <Icon className='self-end' icon="ic:round-keyboard-arrow-down" />}
                                        </div>
                                    </StyledButton_v2>
                                    {openProducts && (
                                        <div className='w-40 bg-white rounded-lg absolute top-[60px] border cursor-pointer'>
                                            <ul className='p-2'>
                                                <li className='px-4 py-2 hover:bg-gray-100 rounded' onClick={clickNav('/solution/accounting')}>
                                                    <div className='flex flex-row items-center gap-x-2 justify-start text-sm font-semibold'>
                                                        <StyledIcon name="accounting"/>
                                                        <p>Accounting</p>
                                                    </div>
                                                </li>
                                                <li className='px-4 py-2 hover:bg-gray-100 rounded' onClick={clickNav('/solution/manufacture')}>
                                                    <div className='flex flex-row items-center gap-x-2 justify-start text-sm font-semibold'>
                                                        <StyledIcon name="manufacture"/>
                                                        <p>Manufacture</p>
                                                    </div>
                                                </li>
                                                <li className='px-4 py-2 hover:bg-gray-100 rounded' onClick={clickNav('/solution/inventory')}>
                                                    <div className='flex flex-row items-center gap-x-2 justify-start text-sm font-semibold'>
                                                        <StyledIcon name="inventory"/>
                                                        <p>Inventory</p>
                                                    </div>
                                                </li>
                                                <li className='px-4 py-2 hover:bg-gray-100 rounded' onClick={clickNav('/solution/crm')}>
                                                    <div className='flex flex-row items-center gap-x-2 justify-start text-sm font-semibold'>
                                                        <StyledIcon name="crm"/>
                                                        <p>CRM</p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>

                                    )}
                                </div>
                            </li>
                            <li>
                                <Link to="/casestudy">
                                    <StyledButton_v2 type='tertiary' className='hidden lg:block font-semibold'>
                                        <FormattedMessage id="nav_casestudy" />
                                    </StyledButton_v2>
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact">
                                    <StyledButton_v2 type='tertiary' className='hidden lg:block font-semibold'>
                                        <FormattedMessage id="nav_contact_us" />
                                    </StyledButton_v2>
                                </Link>
                            </li>
                            <li>
                                <Link to="/blog">
                                    <StyledButton_v2 type='tertiary' className='hidden lg:block font-semibold'>
                                        Articles
                                    </StyledButton_v2>
                                </Link>
                            </li>
                        </ul>
                    </nav>
                </div>
                <div className="flex gap-x-2 items-center justify-end">
                    {/* <Dropdown>
                        <Option value='ID'>
                            <div className='flex flex-row items-center gap-x-2'><img src={flagID} alt="Logo"/> <p>ID</p></div>
                        </Option>
                        <Option value='EN'>
                            <div className='flex flex-row items-center gap-x-2'><img src={flagEN} alt="Logo"/> <p>EN</p></div>
                        </Option>
                    </Dropdown>  */}
                    <div className='flex flex-col'>
                        <StyledButton_v2 type='border' className='hidden lg:block' onClick={() =>setOpenLanguage(!openLanguage)} style={{paddingLeft: '10px', paddingRight: '10px'}}>
                            <div className='flex flex-row items-center gap-x-2 justify-between text-sm'>
                                <img src={selectedLocale == 'id' ? flagID : flagEN} width='18px' alt="Logo"/> <p>{selectedLocale.toUpperCase()}</p>
                                { openLanguage ? <Icon className='self-end' icon="ic:round-keyboard-arrow-up" /> : <Icon className='self-end' icon="ic:round-keyboard-arrow-down" />}
                            </div>
                        </StyledButton_v2>
                        {openLanguage && (
                            <div className='w-24 bg-white rounded-xl absolute top-[60px] border cursor-pointer'>
                                <ul className='p-2'>
                                    <li className='p-2 hover:bg-gray-100 rounded h-8 flex flex-row justify-start items-center' onClick={() => changelang('ID')}>
                                        <div className='flex flex-row items-center gap-x-2 justify-start text-sm font-semibold'>
                                            <img src={flagID} width='20px' alt="Logo"/> <p>ID</p>
                                        </div>
                                    </li>
                                    <li className='p-2 hover:bg-gray-100 rounded h-8 flex flex-row justify-start items-center' onClick={() => changelang('EN')}>
                                        <div className='flex flex-row items-center gap-x-2 justify-start text-sm font-semibold'>
                                            <img src={flagEN} width='20px' alt="Logo"/> <p>EN</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                        )}
                    </div>
                    <div className='border-l h-8 ml-4 mr-4'></div>

                    {/* <select value={selectedLocale} onChange={changeLocaleHandler}>
                        <option value='en'>EN</option>
                        <option value='id'>ID</option>
                        <option value='cn'>CN</option>
                    </select> */}

                    <Link to="https://erp.dsnusantara.com/web/login">
                        <StyledButton_v2 type='border' className='hidden lg:block'>
                            <FormattedMessage id="login_button" />
                        </StyledButton_v2>
                    </Link>
                    <Link to="/demo">
                        <StyledButton_v2 type='primary' className='hidden lg:block'>
                            <FormattedMessage id="demo_button" />
                        </StyledButton_v2>
                    </Link>
                    
                    {!openMenu ? 
                    <Icon icon="ic:outline-menu" width="24px" className='block lg:hidden cursor-pointer' onClick={clickOpenMenu}/>
                    :
                    <Icon icon="uil:times" width="24px" className='block lg:hidden cursor-pointer' onClick={clickOpenMenu}/>
                    }
                </div>
                {/* menu in mobile view */}
            </div>
            {openMenu &&
            (
            <div className='bottom-0 top-10 w-full bg-white flex justify-between flex-col px-5 mt-10 pb-10 fixed z-40 '>
                <ul className='text-extralarge text-dark font-semibold cursor-pointer pt-4 flex flex-col '>
                    <li className='flex flex-col py-3 border-b'>
                        <div onClick={openProductNav} className='flex flex-row items-center justify-between'>
                            <p><FormattedMessage id="nav_product" /></p>
                            <Icon icon="ic:round-keyboard-arrow-down" />
                        </div>
                        {openProductMenu &&
                            (
                                <ul className='block text-small font-medium text-dark mt-2.5 '>
                                    
                                    <li className='py-3 px-2 flex items-center gap-x-3' onClick={clickNav('/solution/accounting')}>
                                        <StyledIcon name="faktur" />Accounting
                                    </li>
                                    <li className='py-3 px-2 flex items-center gap-x-3' onClick={clickNav('/solution/crm')}>
                                        <StyledIcon name="crm" />CRM
                                    </li>
                                    <li className='py-3 px-2 flex items-center gap-x-3' onClick={clickNav('/solution/inventory')}>
                                        <StyledIcon name="inventory" />Inventory
                                    </li>
                                    <li className='py-3 px-2 flex items-center gap-x-3' onClick={clickNav('/solution/manufacture')}>
                                        <StyledIcon name="manufacture" />Manufacture
                                    </li>
                                </ul>
                            )
                        }
                    </li>
                    <li onClick={clickNav('/case_study')} className='py-3 border-b'>Studi Kasus</li>
                    <li onClick={clickNav('/contact')} className='py-3 border-b'>Hubungi Kami</li>
                    <li onClick={clickNav('/blog')} className='py-3 border-b'>Artikel</li>
                </ul>
                <div>
                    <Link to="https://erp.dsnusantara.com/web/login">
                        <StyledButton_v2 type='tertiary' className='w-full mb-4'>Masuk</StyledButton_v2>
                    </Link>
                    <Link to="/demo">
                        <StyledButton_v2 type='primary' className='w-full' >Demo Gratis</StyledButton_v2>
                    </Link>
                    
                </div>
            </div>
            )}

        </header>
        </>
    );
}
export default Navbar_v2;